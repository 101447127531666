import React, { Component } from 'react'
import axios from 'axios';
import SubHome from '../cliam/SubHome';
import {ProductConsumer} from '../../ContextApi'

let pk
export default class Home extends Component {
  static contextType = ProductConsumer

 
  render() {
     
    return (
      <ProductConsumer>
        {context_data => {
          const {
            affiliate_id,
            name,
            EmailFunction

          } = context_data;

      
          return (
            <>
              <SubHome/>
            </>
               
          );
        }}
      </ProductConsumer>
    );
  }
}



