import React, { Component } from 'react'

import { ProductConsumer } from "../../ContextApi";
import { NavLink, Redirect } from "react-router-dom";
import {Button,Modal,Form} from 'react-bootstrap'
// import YouTube from 'react-youtube';
import ReactPlayer from 'react-player'
import axios from "axios";
import Duration from '../Duration'
import F2 from './Fp2'
import homelog from '../logo/agencylogo.png'
import homeAgency from '../logo/ageny.png'
import Watch from '../logo/watch.png'
import Mpobile from '../logo/mpobile.png'
// import Chat from '../logo/chat.png'
import Arooupw from '../logo/uparrow.png'
import Play from '../logo/play.png'

let lol=""

export default class SubHome extends Component {
 static contextType = ProductConsumer;
state={
    pip: false,
     playing: false,
    controls: true,
    light: false,
    volume: 0.8,
    muted: false,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    videowatch:false,
    reactvideo:"https://toshimarkets.nyc3.cdn.digitaloceanspaces.com/Video%20On%20Setupmywallet.com.mp4",
    reactthem:"https://terminal.com.ams3.digitaloceanspaces.com/Setupmywallet.instantfunnels.com.mp4",
    buttontext:"Get Started",
    buttonlink:"",
    nextapicall:false,
}
setCurrenciesList = async () => {
  await axios
    .get(
      `https://storeapi.apimachine.com/dynamic/GXFunnels/Setupmywallet?key=7d9e0009-7fb8-465a-a561-02cd0cbd4cba`
    )
    .then(async res => {
  
      // this.setState({
      //   currency: res.data.coins
      // });
      let value="getethereumtoday.com"
      let n1= res.data.data.filter((user)=>{

        return (user.Key).toUpperCase().includes((value).toUpperCase());
   
  })


  let b1=n1.map(item=>{
    // let s=item.formData
    return(
      
   item.formData.videothumbnail
)}
  )
  let b2=n1.map(item=>{
    // let s=item.formData
    return(
      
  item.formData.videolink
)}
  )
  let b3=n1.map(item=>{
    // let s=item.formData
    return(
      
  item.formData.buttontext
)}
  )
  
  let b4=n1.map(item=>{
    // let s=item.formData
    return(
      
  item.formData.buttonlink
)}
  )
  console.log("firstaxios",b3);
   this.setState({

   reactthem:b1[0],
   buttontext:b3[0],
   buttonlink:b4[0],
   nextapicall:true,
 });
 if(this.state.nextapicall===true)
{
  await axios
  .post(
    `https://vod-backend.globalxchange.io/get_user_profiled_video_stream_link`,{
      video_id:b2[0]
    }
  )
  .then(async res => {
console.log("dasdasd",res.data)
await this.setState({
  reactvideo:res.data,
})
  });
}
    });
};
 componentDidMount=()=>
 {
   this.setCurrenciesList()
  // ReactGA.initialize('UA-157965665-1');
// console.log("sfdsfd",window.location.pathname + window.location.search);
this.context.updatePathName(window.location.pathname + window.location.search)

 }
 handlePlayPause = () => {
  this.setState({ playing: !this.state.playing })
}

handleStop = () => {
  this.setState({ url: null, playing: false })
}

handleToggleControls = () => {
  const url = this.state.url
  this.setState({
    controls: !this.state.controls,
    url: null
  }, () => this.load(url))
}

handleToggleLight = () => {
  this.setState({ light: !this.state.light })
}

handleToggleLoop = () => {
  this.setState({ loop: !this.state.loop })
}

handleVolumeChange = async(e) => {
  await this.setState({ volume: parseFloat(e.target.value) })
  console.log("voube",this.state.volume)
}

handleToggleMuted = () => {
  this.setState({ muted: !this.state.muted })
}

handleSetPlaybackRate = e => {
  this.setState({ playbackRate: parseFloat(e.target.value) }
  
  
  )
  console.log("playbackRate",this.sate.playbackRate)
}

handleTogglePIP = () => {
  this.setState({ pip: !this.state.pip })
}

// handlePlay = (state) => {
//   // console.log('onPlayz',this.state)
//   this.setState({ playing: true })

// }

handleEnablePIP = () => {
  console.log('onEnablePIP')
  this.setState({ pip: true })
}

handleDisablePIP = () => {
  console.log('onDisablePIP')
  this.setState({ pip: false })
}



handleSeekMouseDown = e => {
  this.setState({ seeking: true })
}

handleSeekChange = e => {
  this.setState({ played: parseFloat(e.target.value) })
}

handleSeekMouseUp = e => {
  this.setState({ seeking: false })
  this.player.seekTo(parseFloat(e.target.value))
}

// handleProgress = state => {
//  console.log('onProgress', state)
//   // We only want to update time slider if we are not currently seeking
//   if (!this.state.seeking) {
//     this.setState(state)
//   }
// }

handleEnded =async () => {
  console.log('onEnded',this.context.seekvideo)
await this.setState({ playing: this.state.loop ,

  })
  if(this.context.seekvideo===false)
  {
    await this.setState({ playing: this.state.loop ,
      videowatch:true,
      })
  }
}

handleDuration = (duration) => {
  console.log('onDuration', duration)
  this.setState({ duration })
}





  render() {
    console.log('sonstart',this.state.reactthem )
    // console.log('spused',lol )
// console.log("Played",this.state.duration)
const { url, controls, light, volume, muted, loop, loaded, duration, pip } = this.state
    return (
      <ProductConsumer>
        {context_data => {
          const {
            handleShow,
            handleClose,
            show,
            affiliate_id,
            inputValue,
            OnBuffer,
            played,
            handlePause,
            handleProgress,
            VideoOnPlay,
            VideoonPause,
            sendData,
            videoOnePlay,
            onPlaybackRateChangedata,
            VideoOnwatch,
            NameFunction,
            SendAllData,
            handlePlay,
            playing,
            onstartfun,
            OnseekFun,
            EmailSendcall,
            submitedata,
            Emailsubmitedata,
            videostartplaing,
            helooclick,
            handleOnReadys,
next,
stopPageOneTimmer,

          } = context_data;

          console.log("voube",this.state.volume)
        // console.log("c",this.state.played)
        
        // console.log("cpayed",this.state.duration)
    let aff=  localStorage.getItem("affiliate_id");
          return (
         
  
            <>

<div className="navbarsection">
  <div className="ada">
    <img  className="wwr"  src={homeAgency}/>   

</div>
<div className="asdasrww3">
<img    src={homeAgency}/>   
</div>
<label >
  <a className="letschart" href="https://api.whatsapp.com/send?phone=16477234329&text=&source=&data=" target="_blank">
    Lets Chat
  </a>
</label>
</div>

<div className='justichyrox'>
<div className="row m-0 mobilerow ">

  <div className="col-md-6 asdasd" >
   <h1 className="headtexttitle">Ethereum Is The Next  </h1>
    <h1 className="headtexttitle">Bitcoin</h1>
    <p className="textpargraph">Ethereum is the worlds second most popuplar cryptocurrency and it is on track to outpace Bitcoin. Learn about how you can add ETH to your portfolio today.</p>
 
<div className="mobileshowetile">
<h1 className="ittime"> You Need To Buy</h1>
   <h1 className="headtexttitlemobile">Ethereum </h1>
</div>  


<div className="adsadsasdax">
{
  videostartplaing?
  <label >
  <a href={`https://setupmywallet.com/${aff}`} target="_blank" className="asdfasdz">
    {this.state.buttontext}
  </a>
</label>

  :
  <>
<div className="oasmdlkamsldkas">
<div className="" onClick={()=>handlePlay}>

  <h2 className="ntjasiusd">Watch Video</h2>
  </div>
  <img className="watchvideo" src={Watch} alt=""/>
</div>
 
  </>
}
</div>
  </div>
  <div className="col-md-6 justichyro player-wrapper"  onClick={()=>handlePlay}>
 
  
  <ReactPlayer url={this.state.reactvideo}
         className="player_video"
        //  playing={playing}
         controls={controls}
         light={this.state.reactthem}
        //  loop={false}
        //  volume={volume}
        //  muted
      
    
        onReady={handleOnReadys}
         onStart={onstartfun}
         onPlay={handlePlay}
         onPause={handlePause}
         onBuffer={OnBuffer}
         onSeek={OnseekFun}
         onEnded={this.handleEnded}
         onProgress={handleProgress}
         playing={true}
         muted
         config={{ file: { attributes: {
           autoPlay: true,
           muted: false
         }}}}
        
          />


      
</div>
<div style={{textAlign:"center"}} className="iuwrheg">
  

  <p className="mobilehead1">Bitcoin Is The Best Performing Asset Over Last Decade. Learn How You Can Get Some Bitcoin In Your Portfolio Today..</p>

</div>

<div className="divsection" >




<label className="sgsfg" >
  <a  className="asdfasdzfalse">
  Watch Video

  </a>
</label>

</div>

</div>

</div>



{/* <div className="mobileversion">
<div>
<h1 className="ittime">  It’s Time To Grow Your</h1>
   <h1 className="headtexttitlemobile"> CryptoBrand</h1>
</div>
<div>
  <p className="mobilehead1">Learn How You May Qualify For A Fully Whitelabeled  Wallet App</p>
</div>
<ReactPlayer url='https://toshimarkets.nyc3.cdn.digitaloceanspaces.com/Video%20On%20Setupmywallet.com.mp4'
         className="player_video"
              playing={false}
         controls={controls}
        
         loop={false}
         volume={volume}
         muted={false}
         onReady={() => console.log('onReady')}
         onStart={onstartfun}
         onPlay={handlePlay}
         onPause={handlePause}
         onBuffer={OnBuffer}
         onSeek={OnseekFun}
         onEnded={this.handleEnded}
         onProgress={handleProgress}
          />
<div className="divsection" >


{
  this.state.videowatch?
<label className="sgsfg" >
  <a  href={`https://setupmywallet.com/${aff}`} target="_blank" className="asdfasdz">
    Get Started
  </a>
</label>

  :
<>

<label className="sgsfg" >
  <a  className="asdfasdzfalse">
  Video In Process
  </a>
</label>
  </>
}
          </div>
</div> */}




             <div >
            <div >
                <div >
                <div >


 <div  >
   <>
   {/* <YouTube
        videoId='3cbd0-nyc3b-nyc-zg02'
        opts={opts}
     
        onPause={VideoonPause} 
     
        onPlay={VideoOnPlay}  

      /> 
       */}
   

 </>
 </div>



            </div>
 
            </div>
            </div>



          

      <Modal show={show} onHide={handleClose} enforceFocus={false}  backdrop= 'static'
    keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Your Details
</Modal.Title>
        </Modal.Header>
        <Modal.Body>


        <Form>

        <Form.Group controlId="formBasicname">
    <Form.Label>Name</Form.Label>
    <Form.Control type="text"  name="name"  placeholder="Enter Your Name" onFocus={NameFunction}  onChange={inputValue}  />

  </Form.Group>
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Email address</Form.Label>
    <Form.Control type="email" name="email"  placeholder="name@email.com"  onChange={inputValue}  required />
   
  </Form.Group>



</Form>
        </Modal.Body>
        <Modal.Footer>
       {next?
    <NavLink to="/Fp2"   >  <Button  className="next" onClick={Emailsubmitedata} >
     Next
          </Button></NavLink> 
        :""}
        </Modal.Footer>
      </Modal>
      
      </div>
            </>
               
          );
        }}
      </ProductConsumer>
    );
  }
}



