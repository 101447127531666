import React, { Component, createContext } from 'react'
import axios from 'axios'
export const Agency = createContext()
export class AgencyProvider extends Component {

  state = {
    listdata:[]
  }
   AssistantFucntion = async ()=>{

    await axios
      .get(`https://comms.globalxchange.com/coin/vault/assistant/get`) 
      .then(res => {
        if (res.data.status) {
this.setState({
  listdata:res.data.users
})
   }

      });

  }
  componentDidMount=()=>{
    this.AssistantFucntion()
  }
  render() {
    
    return (
      <Agency.Provider


      
        value={{
          ...this.state,
      
          
        }
        }
      >
        {/* <ContextDevTool context={Agency} id="uniqContextId" displayName="Context Display Name" /> */}
        {this.props.children}
      </Agency.Provider>
    )
  }
}
