import React, { Component } from 'react'

export default class Gx extends Component {
  render() {
   
    //  const {idel}=this.props.collectionIdeal

    const idel = this.props.collectionIdeal.map(x => x.formData.idealaudience)
  
    return (
      <div className="asda">
            <h1 className="headertexting"style={{color:"#3485C0"}}>There Are {this.props.GxLenght} Funnels For GxToken</h1>


{
  this.props.showIdael?
  <>

<div className="row">
  <div className="col-md-6"> 
  <section class="horizontal-scroll">



{
  this.props.GxCollection.length>0?
  <>
  
   {
      this.props.collectionIdeal.map((item,i)=>{
        return(

          
          <div class="item"  >
          <>
          <div onClick={()=>this.props.handlingtogglecard(item)} >
          <img src={item.formData.thumbnail} alt=""/>
          <p className="funnelname"style={{color:"#3485C0"}}>Funnel Name:</p>
          <p className="funneldescib"style={{color:"#3485C0"}}>{item.formData.funnelname}</p>

          </div>
          <div className="buttontwo"> 
            <label className="idealbuttom" style={{opacity:"0.3"}}>Ideal Audience</label>

            <label className="linkstoken" onClick={()=>this.props.handlingLink(item)}>Get My Link</label>
          </div>
          </>
    
        </div>
        )
      })
    }
  </>
  :null
}

    </section>
  </div>
  <div className="col-md-6">
    <h3 className="gxidealheader" style={{color:"#3485C0"}}>Ideal Audience</h3>
    <div className="gxideal" style={{color:"#3485C0"}} dangerouslySetInnerHTML={{ __html: idel }} />
  </div>

</div>
  </>
  :
  <>
  <section class="horizontal-scroll">
   {
    this.props.GxCollection.map((item,i)=>{
        return(

          
          <div class="item" style={this.props.Cardactive?item.Key===this.props.showid?{opacity:"1"}:{opacity:"0.4"}:null} >
            <>
            <div onClick={()=>this.props.handlingtogglecard(item)} >
           <img src={item.formData.thumbnail} alt=""/>
            <p className="funnelname" style={{color:"#3485C0"}}>Funnel Name:</p>
            <p className="funneldescib" style={{color:"#3485C0"}}>{item.formData.funnelname}</p>

            </div>
            <div className="buttontwo"> 
              <label className="idealbuttomGXtoken" onClick={()=>this.props.idealaduicen(item)}>Ideal Audience</label>
              <label className="linkstoken" onClick={()=>this.props.handlingLink(item)}>Get My Link</label>
            </div>
            </>
      
          </div>
        )
      })
    }
    </section>

  </>
}
















      </div>
    )
  }
}
