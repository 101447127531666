import React, { Component } from 'react'
import Gx from '../Component/Gx';
import Insta from '../Component/insta';
import Assets from '../Component/Assets';
import BrokerApp from '../Component/BrokerApp';
import Accency from '../Component/Accency';
import GXtoken from '../Component/GXtoken'
import './landing.scss'
 // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { withKnobs, boolean, number } from '@storybook/addon-knobs';
import GXlog from './logo/red.png'
import search from './logo/search.png'
import bitcoin from './logo/bitcoin.png'
import india from './logo/india.png'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import axios from 'axios';
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider';
import { Alert } from 'react-bootstrap';
import Lottie from 'react-lottie';
import animationData from './GxLoader.json';
import cards from '../Component/logo/cards.png'
import fire from '../Component/logo/fire.png'
import Vector from '../Component/logo/Vector.png'
import ass from '../Component/logo/ass.png'
import gxtoken from '../Component/logo/gxtoken.png'
import Nav from './Navbar'
import AssestanimationData from './Asset.json'
export default class HomePage extends Component {
    state = {
        currentSlide: 0,
        GxLenght:0,
        GxCollection:[],
        autoPlay: true,
        mobileshowclass:false,
        Cardactive:false,
        secondcalling:false,
        beignSelect:true,
        showid:1,
        showlink:"Select On Of The Funnels",
        ativename:"Gx",
        collectionIdeal:[],
        Email:"",
        showIdael:false,
        value: '',
        copied: false,
        showtheafflinkul:false,
        GxLoader:true,

cardlist:[
    {
id:1,
img:cards,
title:"Get Free Crypto",
text:"By: IndianInvestor.com"
    },
    {
        id:1,
        img:cards,
        title:"Get Free Crypto",
        text:"By: IndianInvestor.com"
            },
            {
                id:1,
                img:cards,
                title:"Get Free Crypto",
                text:"By: IndianInvestor.com"
                    },
                    {
                        id:1,
                        img:cards,
                        title:"Get Free Crypto",
                        text:"By: IndianInvestor.com"
                            },
                            {
                                id:1,
                                img:cards,
                                title:"Get Free Crypto",
                                text:"By: IndianInvestor.com"
                                    },
                                    {
                                        id:1,
                                        img:cards,
                                        title:"Get Free Crypto",
                                        text:"By: IndianInvestor.com"
                                            },
                                            {
                                                id:1,
                                                img:cards,
                                                title:"Get Free Crypto",
                                                text:"By: IndianInvestor.com"
                                                    },
    {
        id:1,
        img:cards,
        title:"Get Free Crypto",
        text:"By: IndianInvestor.com"
            }
],


        card:[
            {
                id:1,
                img:GXlog,
                active:true,
                name:"funnel",
                des:"InstaCrypto Launches In The USA......",
                link:"instacrypto.instantfunnels.com",
                idel:"Taking shots in the dark and shooting has a chance of hitting bullseye, but only once in a hundred, or maybe thousand times. Do that and you’ll waste all your arrows. Running your business without knowing who your target audience is also like wasting your arrows or money."
              
            },
            {
                id:2,
                active:true,
                img:GXlog,
                name:"funnel",
                des:"InstaCrypto Launches In The USA......",
                link:"instacrypto.instantfunnels.com",
                idel:"Taking shots in the dark and shooting has a chance of hitting bullseye, but only once in a hundred, or maybe thousand times. Do that and you’ll waste all your arrows. Running your business without knowing who your target audience is also like wasting your arrows or money."
            },
            {
                id:3,
                active:true,
                img:GXlog,
                name:"funnel",
                des:"InstaCrypto Launches In The USA......",
                link:"instacrypto.instantfunnels.com",
                idel:"Taking shots in the dark and shooting has a chance of hitting bullseye, but only once in a hundred, or maybe thousand times. Do that and you’ll waste all your arrows. Running your business without knowing who your target audience is also like wasting your arrows or money."
            },
            {
                id:4,
                active:true,
                img:GXlog,
                name:"funnel",
                des:"InstaCrypto Launches In The USA......",
                link:"instacrypto.instantfunnels.com",
                idel:"Taking shots in the dark and shooting has a chance of hitting bullseye, but only once in a hundred, or maybe thousand times. Do that and you’ll waste all your arrows. Running your business without knowing who your target audience is also like wasting your arrows or money."
            },
            {
                id:5,
                active:true,
                img:GXlog,
                name:"funnel",
                des:"InstaCrypto Launches In The USA......",
                link:"instacrypto.instantfunnels.com",
                idel:"Taking shots in the dark and shooting has a chance of hitting bullseye, but only once in a hundred, or maybe thousand times. Do that and you’ll waste all your arrows. Running your business without knowing who your target audience is also like wasting your arrows or money."
            },
            {
                id:5,
                active:true,
                img:GXlog,
                name:"funnel",
                des:"InstaCrypto Launches In The USA......",
                link:"instacrypto.instantfunnels.com",
                idel:"Taking shots in the dark and shooting has a chance of hitting bullseye, but only once in a hundred, or maybe thousand times. Do that and you’ll waste all your arrows. Running your business without knowing who your target audience is also like wasting your arrows or money."
            },
        ],
        showlinktab:false,  
        affilatelink:"", 
    };
    EmailChange = async e => {
        await this.setState({
          [e.target.name]: e.target.value,
         
        });
    }


    ajsdaasdasdas=()=>
    {
        this.setState(
            {
                mobileshowclass:true,
            }
        )
    }
    Insta = (item) => {
       
        this.setState({
            currentSlide: 1,
            GxLoader:true,
            ativename:item,
            mobileshowclass:false,
        })

    
        axios.get(`https://storeapi.apimachine.com/dynamic/GXFunnels/InstaCryptoFunnelCentre?key=7d9e0009-7fb8-465a-a561-02cd0cbd4cba`)
        .then(async res=>
          {
          // console.log(res)
            if(res.data.success === true){
                // console.log("sadasdasd",res.data.data.length)
                await this.setState(
                    {
                GxLenght:res.data.data.length,
                GxCollection:res.data.data,
                GxLoader:false,
             
               
                Cardactive:false,
                showIdael:false,
                    }
                )
            }
    })

     
    };
    idealaduicen=async(item)=>
    {
        console.log("leb",item)
        await this.setState(
            {
                collectionIdeal:[item],
                showIdael:true,
            }
        )
    }

    Gx = (item) => {
        this.setState({
            GxLoader:true,
            currentSlide: 0,
            ativename:item,
            mobileshowclass:false,
        })
        axios.get(`https://storeapi.apimachine.com/dynamic/GXFunnels/GXFunnelCentre?key=7d9e0009-7fb8-465a-a561-02cd0cbd4cba`)
        .then(async res=>
          {
          // console.log(res)
            if(res.data.success === true){
                // console.log("sadasdasd",res.data.data.length)
                await this.setState(
                    {

                GxLenght:res.data.data.length,
                GxCollection:res.data.data,
                GxLoader:false,
             
               
                Cardactive:false,
                showIdael:false,
                    }
                )
            }
    })
     
    };
    assetsg = (item) => {
      

        this.setState({
            GxLoader:true,
            currentSlide: 2,
            ativename:item,
            mobileshowclass:false,
        })
        axios.get(`https://storeapi.apimachine.com/dynamic/GXFunnels/AssetsFunnelCentre?key=7d9e0009-7fb8-465a-a561-02cd0cbd4cba`)
        .then(async res=>
          {
          // console.log(res)
            if(res.data.success === true){
                // console.log("sadasdasd",res.data.data.length)
                await this.setState(
                    {
               
                GxLenght:res.data.data.length,
                GxCollection:res.data.data,
                GxLoader:false,
             
               
                Cardactive:false,
                showIdael:false,
                    }
                )
            }
    })


       
    };

    Broker = async(item) => {
        await this.setState({
            GxLoader:true,
            currentSlide: 3,
            ativename:item,
            mobileshowclass:false,
          
        })
        axios.get(`https://storeapi.apimachine.com/dynamic/GXFunnels/AgencyFunnelCentre?key=7d9e0009-7fb8-465a-a561-02cd0cbd4cba`)
        .then(async res=>
          {
          // console.log(res)
            if(res.data.success === true){
                // console.log("sadasdasd",res.data.data.length)
                await this.setState(
                    {
               
                GxLenght:res.data.data.length,
                GxCollection:res.data.data,
                GxLoader:false,
      
          
                ativename:item,
                Cardactive:false,
                showIdael:false,

                 
        
        
                    }
                )
         
    };
})}

    Agency = async (item) => {
        
       await this.setState({
            GxLoader:true,
            currentSlide: 4,
            ativename:item,
            mobileshowclass:false,
        })
        axios.get(`https://storeapi.apimachine.com/dynamic/GXFunnels/AgencyFunnelCentre?key=7d9e0009-7fb8-465a-a561-02cd0cbd4cba`)
        .then(async res=>
          {
          // console.log(res)
            if(res.data.success === true){
                // console.log("sadasdasd",res.data.data.length)
                await this.setState(
                    {
               
                GxLenght:res.data.data.length,
                GxCollection:res.data.data,
                GxLoader:false,
      
             
                    ativename:item,
                    Cardactive:false,
                    showIdael:false,
        
        
                    }
                )
            }
    })

     
     
    }
    GXToken=(item)=>{
             
        this.setState({
            GxLoader:true,
            currentSlide: 5,
            ativename:item,
            mobileshowclass:false,
        })
        axios.get(`https://storeapi.apimachine.com/dynamic/GXFunnels/GXTokenFunnelCentre?key=7d9e0009-7fb8-465a-a561-02cd0cbd4cba`)
        .then(async res=>
          {
          // console.log(res)
            if(res.data.success === true){
                // console.log("sadasdasd",res.data.data.length)
                await this.setState(
                    {
               
                GxLenght:res.data.data.length,
                GxCollection:res.data.data,
                GxLoader:false,
      
           
            ativename:item,
            Cardactive:false,
            showIdael:false,
               
        
        
                    }
                )
            }
    })

   
    }

updateCurrentSlide = (index) => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
        this.setState({
            currentSlide: index,
        });
    }
}

handlingtogglecard=(item)=>
{

    console.log("link",item)
    this.setState(
        {
            Cardactive:true,
            showid:item.Key, 
            showlink:item.formData.link ,
            beignSelect:true,
            showlinktab:false,
            showtheafflinkul:false,
        }
    )
}

handlingLink=(item)=>
{
    this.setState(
        {
            Cardactive:true,
            showlinktab:true,  
            showid:item.Key, 
            showlink:item.formData.link,
            showtheafflinkul:false,
            beignSelect:false,  
        }
    )
}

CheckApi=async()=>
{

    
  
    axios.get(`https://comms.globalxchange.com/user/details/get?affiliate_id=${this.state.Email}&signup=true`)
    .then(async res=>
      {
      // console.log(res)
        if(res.data.status === true){


            await this.setState(
                {
                
                    // value:`${this.state.showlink}/${this.state.affilatelink}`,
                    affilatelink:res.data.user.affiliate_id,
                    showtheafflinkul:true,
                    showlinktab:false,
                    secondcalling:true,
                }
            )
        }

        if(this.state.secondcalling===true)
        {
            await this.setState(
                {
                
                    value:`${this.state.showlink}${this.state.affilatelink}`,
            
                }
            )
        }
else{
    alert("User Doesn't Exit")
}    
    })

}

componentDidMount=()=>
{
this.onloadGx()


}

onloadGx=()=>
{
    
    axios.get(`https://storeapi.apimachine.com/dynamic/GXFunnels/AgencyFunnelCentre?key=7d9e0009-7fb8-465a-a561-02cd0cbd4cba`)
    .then(async res=>
      {
      // console.log(res)
        if(res.data.success === true){
            // console.log("sadasdasd",res.data.data.length)
            await this.setState(
                {
                GxLenght:res.data.data.length,
            GxCollection:res.data.data,
            GxLoader:false,
                }
            )
        }
})

}





  render() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
      const AssetdefaultOptions = {
        loop: true,
        autoplay: true,
        animationData: AssestanimationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
      
    console.log("hello dude",this.state.GxCollection)
    const createCarouselItemImage = (index, options = {}) => (

        <>
       
        {index===1?
    <>
<div key={index}>
    {
       this.state.GxLoader?
        <Lottie 
	    options={defaultOptions}
        height={400}
        width={400}
      />
      :
      <Gx 
      card={this.state.card}
      GxCollection={this.state.GxCollection}
      showid={this.state.showid}
      Cardactive={this.state.Cardactive}
      handlingtogglecard={this.handlingtogglecard}
      showlink={this.state.showlink}
      collectionIdeal={this.state.collectionIdeal}
      handlingLink={this.handlingLink}
      showIdael={this.state.showIdael}
      idealaduicen={this.idealaduicen}
      GxLenght={this.state.GxLenght}
      />
    }


          
   
           </div>
           <div key={index}>
          
   
          </div>
    </> 
    :null   
    }
        {index===2?
    <>
<div key={index}>
{
       this.state.GxLoader?
        <Lottie 
	    options={defaultOptions}
        height={400}
        width={400}
      />
      :       
<Insta
    card={this.state.card}
    GxCollection={this.state.GxCollection}
    showid={this.state.showid}
    Cardactive={this.state.Cardactive}
    handlingtogglecard={this.handlingtogglecard}
    showlink={this.state.showlink}
    collectionIdeal={this.state.collectionIdeal}
    handlingLink={this.handlingLink}
    showIdael={this.state.showIdael}
    idealaduicen={this.idealaduicen}
    GxLenght={this.state.GxLenght}



/>
  }

   
           </div>
        
    </> 
    :null   
    }
{index===3?
    <>
<div key={index}>
{
       this.state.GxLoader?
        <Lottie 
	    options={AssetdefaultOptions}
        height={400}
        width={400}
      />
      :           
<Assets
   card={this.state.card}
   GxCollection={this.state.GxCollection}
   showid={this.state.showid}
   Cardactive={this.state.Cardactive}
   handlingtogglecard={this.handlingtogglecard}
   showlink={this.state.showlink}
   collectionIdeal={this.state.collectionIdeal}
   handlingLink={this.handlingLink}
   showIdael={this.state.showIdael}
   idealaduicen={this.idealaduicen}
   GxLenght={this.state.GxLenght}
 


/>

}
           </div>
        
    </> 
    :null   
    }

{index===4?
    <>
<div key={index}>
            
<BrokerApp
  card={this.state.card}
  showid={this.state.showid}
  Cardactive={this.state.Cardactive}
  handlingtogglecard={this.handlingtogglecard}
  showlink={this.state.showlink}
  GxCollection={this.state.GxCollection}
  handlingLink={this.handlingLink}
  idealaduicen={this.idealaduicen}
  GxLenght={this.state.GxLenght}
  showIdael={this.state.showIdael}
  collectionIdeal={this.state.collectionIdeal}
/>

   
           </div>
        
    </> 
    :null   
    }
{index===5?
    <>
<div key={index}>
            
<Accency
  card={this.state.card}
  showid={this.state.showid}
  Cardactive={this.state.Cardactive}
  handlingtogglecard={this.handlingtogglecard}
  showlink={this.state.showlink}
  GxCollection={this.state.GxCollection}
  handlingLink={this.handlingLink}
  showIdael={this.state.showIdael}
  idealaduicen={this.idealaduicen}
  collectionIdeal={this.state.collectionIdeal}
  GxLenght={this.state.GxLenght}


/>


   
           </div>
        
    </> 
    :null   
    }
     {index===6?
    <>
<div key={index}>
    {
       this.state.GxLoader?
        <Lottie 
	    options={defaultOptions}
        height={400}
        width={400}
      />
      :
      <GXtoken 
      card={this.state.card}
      GxCollection={this.state.GxCollection}
      showid={this.state.showid}
      Cardactive={this.state.Cardactive}
      handlingtogglecard={this.handlingtogglecard}
      showlink={this.state.showlink}
      collectionIdeal={this.state.collectionIdeal}
      handlingLink={this.handlingLink}
      showIdael={this.state.showIdael}
      idealaduicen={this.idealaduicen}
      GxLenght={this.state.GxLenght}
      />
    }


          
   
           </div>
           <div key={index}>
          
   
          </div>
    </> 
    :null   
    }
    
       </>
    );
    const tooglesGroupId = 'Toggles';
    const valuesGroupId = 'Values';
    const baseChildren = <div>{[1, 2, 3,4,5,6].map(createCarouselItemImage)}</div>;
    const buttonStyle = { fontSize: 20 };
    const containerStyle = {};
    
const getConfigurableProps = () => ({
    showArrows: boolean('showArrows', false, tooglesGroupId),
    showStatus: boolean('showStatus', false, tooglesGroupId),
    showIndicators: boolean('showIndicators', false, tooglesGroupId),
    infiniteLoop: boolean('infiniteLoop', false, tooglesGroupId),
    showThumbs: boolean('showThumbs', false, tooglesGroupId),
    useKeyboardArrows: boolean('useKeyboardArrows', false, tooglesGroupId),
    autoPlay: boolean('autoPlay', false, tooglesGroupId),
    stopOnHover: boolean('stopOnHover', false, tooglesGroupId),
    swipeable: boolean('swipeable', false, tooglesGroupId),
    dynamicHeight: boolean('dynamicHeight', true, tooglesGroupId),
    emulateTouch: boolean('emulateTouch', true, tooglesGroupId),
    transitionTime: number('transitionTime', 550, {}, valuesGroupId),
    swipeScrollTolerance: number('swipeScrollTolerance', 5, {}, valuesGroupId),
});
    return (

         
//                 <div className="displaymainsection">

                    
//                     <div className={this.state.ativename==="BrokerApp Disshow"||this.state.ativename==="Agency Disshow"?"NavSideBroker Disshow":""||this.state.ativename==="GXToken Disshow"?"NavSideGXtoken Disshow":"" ||this.state.ativename==="Assets Disshow"?"NavSideAssets Disshow":"NavSide Disshow"}  style={containerStyle}>
                   
//                     {/* <i class="fas fa-times crossmark"></i> */}
//                     <div style={{    marginTop: "2rem"}}>

//                         {this.state.ativename==="Gx"?
//                         <img  src={Gxs} alt=""/>
//                         :null
//                         }
//                          {this.state.ativename==="Insta"?
//                         <img src={Inst} alt=""/>
//                         :null
//                         }
//                          {this.state.ativename==="Assets"?
//                         <img src={AssetsImg} alt=""/>
//                         :null
//                         }
//                          {this.state.ativename==="BrokerApp"?
//                         <img src={Broker} alt=""/>
//                         :null
//                         }
//                          {this.state.ativename==="Agency"?
//                         <img src={ass} alt=""/>
//                         :null
//                         }
//                          {this.state.ativename==="GXToken"?
//                         <img src={gxtoken} alt=""/>
//                         :null
//                         }





                      
//                     </div>


//                     <div className="marginshow">
//                         <p    className={this.state.ativename==="Gx"?"trueactivename":"choosefalse"} onClick={()=>this.Gx("Gx")}>GX</p>
//                         <p  className={this.state.ativename==="Insta"?"trueactivename":"choosefalse"} onClick={()=>this.Insta("Insta")}>InstaCrypto</p>
//                        <p  className={this.state.ativename==="Assets"?"trueactivename":"choosefalse"} onClick={()=>this.assetsg("Assets")}>Assets</p>
//                         <p  className={this.state.ativename==="BrokerApp"?"trueactivename":"choosefalse"}onClick={()=>this.Broker("BrokerApp")}>BrokerApp</p> 

//                         {/* <p  className={this.state.ativename==="Agency"?"trueactivename":"choosefalse"}onClick={()=>this.Agency("Agency")}>Agency</p> 
//                         <p  className={this.state.ativename==="GXToken"?"trueactivename":"choosefalse"}onClick={()=>this.GXToken("GXToken")}>GXToken</p> */}

                        
                        
//                     </div>
//                    <div className="imgnavbardiv">
//                     <img  className="innvabarimg" src={Fn} alt=""/>
//                    </div>
//                    </div> 

// {/* mobile */}

// {
//     this.state.mobileshowclass?
    

// <div className={this.state.ativename==="BrokerApp mobilshonav"||this.state.ativename==="Agency mobilshonav"?"NavSideBroker mobilshonav":""||this.state.ativename==="GXToken mobilshonav"?"NavSideGXtoken mobilshonav":"" ||this.state.ativename==="Assets mobilshonav"?"NavSideAssets mobilshonav":"NavSide mobilshonav"}  style={containerStyle}>
                   
//                    {/* <i class="fas fa-times crossmark"></i> */}
//                    <div style={{    marginTop: "2rem"}}>

//                        {this.state.ativename==="Gx"?
//                        <img  src={Gxs} alt=""/>
//                        :null
//                        }
//                         {this.state.ativename==="Insta"?
//                        <img src={Inst} alt=""/>
//                        :null
//                        }
//                         {this.state.ativename==="Assets"?
//                        <img src={AssetsImg} alt=""/>
//                        :null
//                        }
//                         {this.state.ativename==="BrokerApp"?
//                        <img src={Broker} alt=""/>
//                        :null
//                        }
//                         {this.state.ativename==="Agency"?
//                        <img src={ass} alt=""/>
//                        :null
//                        }
//                         {this.state.ativename==="GXToken"?
//                        <img src={gxtoken} alt=""/>
//                        :null
//                        }





                     
//                    </div>


//                    <div className="marginshow">
//                        <p    className={this.state.ativename==="Gx"?"trueactivename":"choosefalse"} onClick={()=>this.Gx("Gx")}>GX</p>
//                        <p  className={this.state.ativename==="Insta"?"trueactivename":"choosefalse"} onClick={()=>this.Insta("Insta")}>InstaCrypto</p>
//                       <p  className={this.state.ativename==="Assets"?"trueactivename":"choosefalse"} onClick={()=>this.assetsg("Assets")}>Assets</p>
//                        <p  className={this.state.ativename==="BrokerApp"?"trueactivename":"choosefalse"}onClick={()=>this.Broker("BrokerApp")}>BrokerApp</p> 

//                        {/* <p  className={this.state.ativename==="Agency"?"trueactivename":"choosefalse"}onClick={()=>this.Agency("Agency")}>Agency</p> 
//                        <p  className={this.state.ativename==="GXToken"?"trueactivename":"choosefalse"}onClick={()=>this.GXToken("GXToken")}>GXToken</p> */}

                       
                       
//                    </div>
//                   <div className="imgnavbardiv">
//                    <img  className="innvabarimg" src={Fn} alt=""/>
//                   </div>
//                   </div> 

// :null

//                     }



//                 <div className="carsolueSection">
//  <i class="fas fa-bars  burgun" onClick={this.ajsdaasdasdas} ></i>
               
//                     <Carousel
//                     {...getConfigurableProps()}
//                     axis="vertical"
//                     dynamicHeight={true}
//                         autoPlay={false}
//                         selectedItem={this.state.currentSlide}
//                         onChange={this.updateCurrentSlide}
                   
//                     >
//                          {baseChildren.props.children}
//                     </Carousel>

//                     <div className={this.state.ativename==="BrokerApp"||this.state.ativename==="Agency"?"selectoonfunnerboker":"" ||this.state.ativename==="Assets"?"selectoonfunnerlAssets":"selectoonfunnerl"}>
                      


//                       {
//                           this.state.showtheafflinkul?<>
//    <div style={{width:"100%"}}>
                    
//                     <input type="text" name="Email"  className={this.state.ativename==="BrokerApp"||this.state.ativename==="Agency"?"inputboxemailBroker":"" ||this.state.ativename==="Assets"?"inputboxemailAssets":"inputboxemail"}  value={`${this.state.value}`}
//                       onChange={({target: {value}}) => this.setState({value, copied: false})} readonly />
            
//                       <div className="copylipboard">
            
                   
             
//                     <CopyToClipboard text={this.state.value}
//                       onCopy={() => this.setState({copied: true})}>
//                 <i class="fas fa-copy"></i>
//                     </CopyToClipboard>
             
//                     {this.state.copied ? <span style={{color: '#3485C0'}}>Copied.</span> : null}
//                     </div>
//                   </div>
//                           </>
//                           :null
//                       }
                 
                      
//                         {
//                             this.state.showlinktab?
// <>
// <input type="text" name="Email"  className={this.state.ativename==="BrokerApp"||this.state.ativename==="Agency"?"inputboxemailBroker":"" ||this.state.ativename==="Assets"?"inputboxemailAssets":"inputboxemail"}  onChange={this.EmailChange}  placeholder="Enter Email"/>
// <img className="arrow" src={Arrw} onClick={this.CheckApi} alt=""/>
// </>

//                             :
//                           ""
                            
//                         }


//                         {
//                             this.state.beignSelect?
//                             <p style={{    marginBottom: "0"}}>{this.state.showlink}</p>
//                             :null
//                         }
                        
//                     </div>
//                     </div>
//                 </div>

<>
<Nav></Nav>
<div className='holesection'>


<div className='landing-top-section'>
<div className="div">

</div>
<div className='landing-sub' >
    <h1>Find Funnels That Convert</h1>
    <div className='sub'>
    <div className='drop-down'>
        <img src={bitcoin} alt="" />
        <h2>Crypto</h2>
        <img src={Vector} alt="" />
    </div>


    <div className="searchsection">
<img src={search} alt="" />
<input type="text" placeholder='Search For Crypto Funnels' name="" id="" />
    </div>


    </div>
</div>

</div>




<div className='landing-section'>
 
<div className='top-title-section'>
    <div className='sub-1'>
        <img src={fire} alt="" />
        <h1>Trending Funnels</h1>
    </div>

    <div className='sub-2'>
        <h5>Here Are The Top Three Decks  In You’re Region</h5>
        <label className='labletop'>
<img src={india} alt="" />
        India
        </label>
    </div>
</div>

<div className='landing-section-sub' >
{
 this.state.cardlist.map(item=>{
        return(
            <div className='cards'>

                <div className='imglist' style={{backgroundImage: "url(" + item.img + ")"}}>

                </div>

                <div className='content-section'>
                <div>
                    <h3>{item.title}</h3>
                    <p>{item.text}</p>
                </div>

                <div className='labelc-content'>
                    <label className='label-first'>India</label>
                    <label className='label-last'>Crypto</label>
                </div>
                </div>
                
            </div>
        )
    })
}
</div>
</div>
</div>

</>
    )
  }
}
