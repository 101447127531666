import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import logo from '../Component/logo/logo.png'
import { Navbar,Nav,Container,Button } from 'react-bootstrap';
import './Nav.scss'
function BrandingPage() {
  const history = useHistory();
  const [admin, setAdmin] = useState(false);

  return (
    <>
    
  <div className="custom_navbar">
   <Navbar  expand="lg">
  <Container fluid className="p-0 mobile">
    <Navbar.Brand href="#">
      <img src={logo} alt="" />
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="navbarScroll" />
    <Navbar.Collapse id="navbarScroll">
      <Nav
        className="me-auto my-2 my-lg-0"
        style={{ maxHeight: '100px' }}
        navbarScroll
      >
     
      </Nav>
      <Nav.Link >Templates</Nav.Link>
        <Nav.Link >Solutions</Nav.Link>
        <Nav.Link >Classrooms</Nav.Link>
        <Nav.Link >FunnelExperts</Nav.Link>
     
     
        <Button className="custom-first-buttom"  onClick={()=>history.push('/brands')}>Brands</Button>
        <Button className="custom-last-button">Start</Button>
    </Navbar.Collapse>
  </Container>
</Navbar>
    </div> 








    </>
  );
}

export default BrandingPage;
