import React,{useState} from 'react'
import se from '../logo/se.png'
import logo from '../logo/logo.png'
import cards from '../logo/cards.png'
import indicator from '../logo/indicator.png'
import indiaotc from '../logo/indiaotc.png'
import taxchain from '../logo/taxchain.png'
import csswealth from '../logo/csswealth.png'
import { useHistory } from "react-router-dom";
import s1 from '../logo/s1.png'
import s2 from '../logo/s2.png'
import s3 from '../logo/s3.png'


import Navs from '../Navbar'
import './BrandingPage.scss'
import { Modal,Nav,Container,Button } from 'react-bootstrap';

export default function BrandingPage() {
const [pshow, setpshow] = useState('All Brands')
const [show, setshow] = useState(false)
const [infos,setinfos]=useState('Go To Funnel')
const handleClose = () => setshow(false);
  const handleShow = () => setshow(true);
  const history = useHistory();



  const info=[
      {
          img:s1,
          name:"Ideal Audience",
          text:"Learn Who This Funnel Is Targeting"

      },
      {
          img:s2,
          name:"Go To Funnel",
          text:"Learn Who This Funnel Is Targeting"
      },
      {
        img:s3,  
        name:"Share Funnel",
        text:"Copy To Clipboard"
      }

  ]

  const showhander=(e)=>{
    if(e==="Go To Funnel")
{
    history.push('/funnels/getethereumtoday')
}
}
    const cardlist=[
        {
    id:1,
    img:cards,
    title:"Get Free Crypto",
    text:"By: IndianInvestor.com"
        },
        {
            id:1,
            img:cards,
            title:"Get Free Crypto",
            text:"By: IndianInvestor.com"
                },
                {
                    id:1,
                    img:cards,
                    title:"Get Free Crypto",
                    text:"By: IndianInvestor.com"
                        },
                        {
                            id:1,
                            img:cards,
                            title:"Get Free Crypto",
                            text:"By: IndianInvestor.com"
                                },
                                {
                                    id:1,
                                    img:cards,
                                    title:"Get Free Crypto",
                                    text:"By: IndianInvestor.com"
                                        },
                                        {
                                            id:1,
                                            img:cards,
                                            title:"Get Free Crypto",
                                            text:"By: IndianInvestor.com"
                                                },
                                                {
                                                    id:1,
                                                    img:cards,
                                                    title:"Get Free Crypto",
                                                    text:"By: IndianInvestor.com"
                                                        },
                                                        {
                                                            id:1,
                                                            img:cards,
                                                            title:"Get Free Crypto",
                                                            text:"By: IndianInvestor.com"
                                                                },
                                                                {
                                                                    id:1,
                                                                    img:cards,
                                                                    title:"Get Free Crypto",
                                                                    text:"By: IndianInvestor.com"
                                                                        },
                                                                        {
                                                                            id:1,
                                                                            img:cards,
                                                                            title:"Get Free Crypto",
                                                                            text:"By: IndianInvestor.com"
                                                                                },
                                                                                   {
    id:1,
    img:cards,
    title:"Get Free Crypto",
    text:"By: IndianInvestor.com"
        },   {
            id:1,
            img:cards,
            title:"Get Free Crypto",
            text:"By: IndianInvestor.com"
                }
    
    ]   
     const data=[
    {
        img:indicator,
        name:"All Brands"
    },
    {
        img:indicator,
        name:"IndianInvestor"
    },
    {
        img:indiaotc,
        name:"IndianOTC"
    },
    {
        img:taxchain,
        name:"TaxChains"
    },
    {
        img:csswealth,
        name:"CCSWealth"
    },
    
]

  return (
      <>
      <Navs/>
    <div className='Branding-page'>
      <div className='sub'>
          <h2>All Brands</h2>
      
<div className='search-section'>
    <img src={se} alt="" />
    <input type="text" placeholder='Search For Brand....' name="" id="" />
</div>


<div className="items">
    {
        data.map(item=>{
            return(
                <div className='cards' onClick={()=>setpshow(item.name)} style={pshow==item.name?{background:"rgba(170, 170, 170, 0.17)"}:{opacity:"0.3"}}>
                    <img src={item.img} alt="" />
                    <h5>{item.name}</h5>
                </div>
            )
        })
    }
</div>
      </div>

<div className='item-list-section'>
<div className='content'>
<div className='sub'>
    <h1>Funnels For All Brands</h1>
    <p>All Brands Have 10 Funnels</p>
</div>
<div className='search-section'>
    <img src={se} alt="" />
    <input type="text" placeholder='Search For Brand....' name="" id="" />
</div>
</div>


<div className='landing-section-sub' >
{
 cardlist.map(item=>{
        return(
            <div className='cards' onClick={handleShow}>

                <div className='imglist' style={{backgroundImage: "url(" + item.img + ")"}}>

                </div>

                <div className='content-section'>
                <div>
                    <h3>{item.title}</h3>
                    <p>{item.text}</p>
                </div>

                <div className='labelc-content'>
                    <label className='label-first'>India</label>
                    <label className='label-last'>Crypto</label>
                </div>
                </div>
                
            </div>
        )
    })
}
</div>

</div>

    </div>


<div className='model-handing'>
<Modal 
      aria-labelledby="contained-modal-title-vcenter"
      centered


show={show} onHide={handleClose} animation={false}>
        
       
        <Modal.Body>
            <div className='model-content'>
            <img src={logo} alt="" /> 

            <div className="info">

    {
        info.map(item=>{return(
            <div className="cards" onClick={()=>showhander(item.name)} style={infos===item.name?{opacity:"1"}:{opacity:"0.5"}}>
                <div>
                    <img src={item.img} alt="" />
                </div>
                <div>
                    <h3>{item.name}</h3>
                    <p>{item.text}</p>
                </div>
            </div>
        )})
    }

            </div>
            </div>
        
         
            
            
            </Modal.Body>
        <Modal.Footer>
         <h6>Open Brand Portal</h6>
        </Modal.Footer>
      </Modal>

</div>
  


    </>
  )
}
