import React,{useState, Component } from 'react';
import { Switch, Route ,BrowserRouter,Redirect} from "react-router-dom";
import Alerts from './Component/HomePage'
  import './App.css'
  import "react-responsive-carousel/lib/styles/carousel.min.css";
import Navbar from './Component/Navbar'
import BrandingPade from './Component/BrandingPage/BrandingPage'
import Home from './Component/Home/Home'
import Uk from './Component/uk/Home'
import Mlm from './Component/mlm/Home'
import Cliam from './Component/cliam/Home'
import InstanceFunnel from './Component/HomePageInstance/Homepage'
import CrpyTo from './Component/HomePageCrypto/Homepage'
function App() {
return(
  <React.Fragment>
 <div className="App-contianer">
 

    <Switch>
    <Route
            exact
            path="/crpyto"
            render={() => (
              <CrpyTo/>
            )}
          />
    <Route
            exact
            path="/brands"
            render={() => (
              <BrandingPade/>
            )}
          />
            <Route
            exact
            path="/instantFunnel"
            render={() => (
              <InstanceFunnel/>
            )}
          />
                 <Route
            exact
            path="/Cliam"
            render={() => (
              <Cliam/>
            )}
          />
             <Route
            exact
            path="/mlm"
            render={() => (
              <Mlm/>
            )}
          />
            <Route
            exact
            path="/uk"
            render={() => (
              <Uk/>
            )}
          />
             <Route path="/funnels/getethereumtoday/:affiliate_id" exact render={props => <Home {...props} />} />
         <Route path="/funnels/getethereumtoday" exact render={() => <Redirect to="/funnels/getethereumtoday/1" />}/>

{/* <Route
            exact
            path="/funnels/getethereumtoday"
            render={() => (
              <Home/>
            )}
          /> */}
      <Route
        path="/"
        render={() => (
          <Alerts/>
         
        )}
      />
    
    </Switch>
  </div>
</React.Fragment>
  
 
   
  );
}

export default App;
