import React ,{useEffect,useState}from 'react'
import InstantFunnel from '../../Image/InstantFunnel.png'
import inputArraw from '../../Image/inputArraw.png'

import './Homepage.scss'
export default function Homepage() {
    const [changeSize, setChangeSize] = useState("");

    useEffect(()=>{
        let ele = document.getElementById('homepage');
     
 if(ele.offsetWidth<=900)
{
    setChangeSize("What Do You Need Help With?")
}
else{
    setChangeSize("Search Whatever You Need Help With")  
}
        window.addEventListener('resize', handleSize);
        return ()=>{
            window.removeEventListener('resize', handleSize)
        }
    },[changeSize]);
    const handleSize = () =>{
   
    }
  return (
    <div id="homepage" className="homepage"  data-aos="zoom-in">
      <div className="cutonhome" style={{textAlign:"center"}}>
      <img className="logo"  src={InstantFunnel} alt=""/>
          <div style={{position:"relative"}} className="topinputdiv">
          <input type="text" name="" placeholder={changeSize} id=""/>
          <img  className="imgsearc" src={inputArraw} alt=""/>
          </div>
 
          <div className="twobuuton">
              <label className="hlogin">Brand Portal</label>
              <label className="hStarted">Hire An Expert</label>
          </div>
    
      </div>
      <div className="bottombrand">
<p className="notshowmobile">Do You Manage Sales Reps? </p>
<p className="showmobile">Do You Manage Sales Reps?</p>
  <label >Click Here</label>
  <p className="notshowmobile">To Virtualize Your Sales System</p>
          </div>
    </div>
  )
}
