import React ,{useEffect,useContext,useState}from 'react'
import Crytoassets from '../../Image/Crytoassets.png'
import inputArraw from '../../Image/inputArraw.png'

import './Homepage.scss'
import search from '../../Image/search.png'
import Delete from '../../Image/Delete.png'
import back from '../../Image/back.png'
import {useHistory} from 'react-router-dom';

import { Agency } from '../ContextApi/ContextApi';
export default function Homepage() {
    const button=[
        {
            name:'Assistants',
        },
        {
            name:'Categories',
        },
    ]
    const agency = useContext(Agency);
    const {  listdata} = agency
    const history = useHistory();
   
    const [changeSize, setChangeSize] = useState("");
const [toggle,settoggle]=useState('main')
const [searchtab,setsearchtab]=useState('Assistants')
const [mobile,setmobile]=useState("false")
    useEffect(()=>{
        let ele = document.getElementById('homepage');
     
 if(ele.offsetWidth<=600)
{
    setChangeSize("What Do You Need Help With?")
    setmobile("true")


}
else{
    setChangeSize("Search Whatever You Need Help With")  
    setmobile("false")
}
        window.addEventListener('resize', handleSize);
        return ()=>{
            window.removeEventListener('resize', handleSize)
        }
    },[changeSize]);
    const handleSize = () =>{
   
    }
    // useEffect(() => {
    //     AOS.init({ duration: 2000 });
    //     AOS.refresh();
    // }, []);

    const togglehomepage=()=>{

        switch(toggle){
case "main":
    return(
    <div id="homepage" className="homepage"  >
      <div className="cutonhome" style={{textAlign:"center"}}>
      <img className="logo"  src={Crytoassets} alt=""/>
          <div style={{position:"relative"}} className="topinputdiv">
              {
                  mobile==="true"?
                  <input type="text" name="" placeholder="What Do You Need Help With?" onClick={()=>settoggle("search")}/>
                  :
                  <input type="text" name="" placeholder="Search Whatever You Need Help With" id=""/>
              }
  
          <img  className="imgsearc" src={inputArraw} alt=""/>
          </div>
          {
                  mobile==="true"?
                  <div className="twobuuton">
                  <label className="hlogin" onClick={ ()=>settoggle("search")}>Assistants</label>
                  <label className="hStarted">Categories</label>
              </div>
                  :
                  <div className="twobuuton">
                  <label className="hlogin" onClick={()=> history.push("/assistants")}>Assistants</label>
                  <label className="hStarted">Categories</label>
              </div>

          }
 
        
    
      </div>
      <div className="bottombrand">
<p className="notshowmobile">Interested In Earning Crypto By Helping Others</p>
<p className="showmobile">Become An Assistant Today</p>
  <label >Click Here</label>
          </div>
    </div>
    )
    case "search":
        return(
            <div className="SearchBarMainHome">
    <div className="logoSectionMain">
    <img className="logo"  src={Crytoassets} alt="" onClick={()=>settoggle("main")}/>
    </div>
    <div>
        <div className="HandlingSearch">


<img className="back" src={back} alt=""onClick={()=>settoggle("main")}/>
<input type="text" placeholder="Start Typing..."/>
<div>
<img src={Delete} alt=""/>
</div>

<div>
<img src={search} alt=""/>
</div>

        </div>
        <div className="buttonSection">
            {
                button.map(item=>{
                    return(
           
                          <label className={item.name===searchtab?"true":"false"}>{item.name}</label>
           
                    )
                })
            }


        </div>

        <div>
          {
              listdata.map(item=>{
                  return(
                      <div className="Asssitantlist"> 
                      <div style={{display:"flex",alignItems:"center"}}>
                          <img src={item.profile_img} alt=""/>
                          <div style={{marginLeft:"1rem"}}>
                          <h3>{item.assistantTag}</h3>
                          <p>{item.email}</p>
                      </div>
                      </div>
                      <div style={{display:"flex"}}>
                          <label className="messagelabel" >Message</label>
                          <label className="meetinglabel" >Meeting</label>
                      </div>
                      </div>
                   
                  )
              })
          }
      </div>
    </div>

</div>
        )
        }
    }
  return (
      <>
{togglehomepage()}
</>
  )
}
